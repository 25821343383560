import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../../styles/theme'
import { NextLink } from '../../../common/functionality/NextLink'
import { NavigationArrow } from '../../../assets/components/NavigationArrow'
import { tabletUpMq } from '../../../styles/mediaQueries'
import { useFeatureFlag } from '../../auth/hooks/useFeatureFlag'
import { featureFlags } from '../../auth/FeatureFlagProvider'
import { shopRoute } from '../../../common/constants/routes'
import { transientOptions } from '../../../common/style/transientOptions'

const promotionUrl =
  'https://nest.api.upgradehealth.com/landing-redirect?landing_page_key=LIVE180&redirect_url=https%3A%2F%2Fapp.upgradehealth.com%2Fshop%2Flabs%3Ftab%3Dpackages%26detailsPackageSlug%3Dpkg_level_3_lc%26conferencePromotionUrlRedirect%3Dtrue'

const Container = styled.div`
  background: ${theme.colors.darkCloud};
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const HeaderSection = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  max-width: 438px;
  align-self: auto;
`

const Title = styled.div`
  color: ${theme.colors.grayMidnight};
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  width: 244px;

  ${tabletUpMq} {
    width: 100%;
  }
`
const Subtitle = styled.div`
  color: ${theme.colors.grayCoolGray};
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.07px;
  align-self: stretch;
`

const PromoText = styled.span`
  color: ${theme.colors.aegean};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
`

const Bubble = styled.div`
  width: fill-available;
  align-self: center;
  color: ${theme.colors.grayCoolGray};
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  align-self: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: fill-available;

  ${tabletUpMq} {
    flex-direction: row;
    width: 100%;
    max-width: ${theme.spacing.standardPageMaxContentWidth};
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }
`

const BubbleLineItem = styled('div', transientOptions)<{ $index: number }>`
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: fill-available;

  ${tabletUpMq} {
    padding: 12px;
    border-radius: 12px;
    /* this is very stupid but on a time crunch. fix if we hit this area again */
    width: ${(p) => (p.$index === 0 ? '324px' : p.$index === 1 ? '368px' : '316px')};
  }
`

const ShopLink = styled(NextLink)`
  border-radius: 4px;
  background: ${theme.colors.grayPorcelain};
  display: flex;
  width: 165px;
  padding: 8px 12px 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: ${theme.colors.cerulean};
  font-size: 15px;
  font-weight: 600;
`

export function ConferencePromotionAnnouncement() {
  const { isEnabled } = useFeatureFlag(featureFlags.switchHomePageBannerToNonPromoVersion)

  const content = React.useMemo(
    () =>
      isEnabled
        ? {
            title: 'Become a Member',
            subtitle: 'Add a membership at checkout to receive exclusive pricing on supplements and lab test packages.',
            url: shopRoute,
          }
        : {
            title: 'Biohacking Conference Exclusive Offer',
            subtitle: (
              <>
                Get one year free membership by purchasing a lab package and applying the promo code{' '}
                <PromoText>LIVE180</PromoText> at the cart to get:
              </>
            ),
            url: promotionUrl,
          },
    [isEnabled]
  )

  return (
    <Container>
      <HeaderSection>
        <Title>{content.title}</Title>
        <Subtitle>{content.subtitle}</Subtitle>
      </HeaderSection>
      <Bubble>
        <BubbleLineItem $index={0}>Access to the Upgrade Labs Health Platform</BubbleLineItem>
        <BubbleLineItem $index={1}>Access to premium supplements and curated lab packages</BubbleLineItem>
        <BubbleLineItem $index={2}>Personalized recommendations from Dave Asprey</BubbleLineItem>
      </Bubble>
      <ShopLink href={content.url}>
        Shop Now <NavigationArrow style={{ marginLeft: 2 }} orientation="right" color={theme.colors.cerulean} />
      </ShopLink>
    </Container>
  )
}
